















import {Component, Watch} from 'vue-property-decorator';
import ContactsList2 from '@/components/ContactsList2.vue';

import {Getter} from 'vuex-class';
import {mixins} from 'vue-class-component';
import Notifications from '@/components/mixins/Notifications';

@Component({
  name: 'directory',
  components: {ContactsList2}
})
export default class Directory extends mixins(Notifications) {
}
